import { render, staticRenderFns } from "./ECommerceShop.vue?vue&type=template&id=50b44e4e&scoped=true&"
import script from "./ECommerceShop.vue?vue&type=script&lang=js&"
export * from "./ECommerceShop.vue?vue&type=script&lang=js&"
import style0 from "./ECommerceShop.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ECommerceShop.vue?vue&type=style&index=1&id=50b44e4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b44e4e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2024/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50b44e4e')) {
      api.createRecord('50b44e4e', component.options)
    } else {
      api.reload('50b44e4e', component.options)
    }
    module.hot.accept("./ECommerceShop.vue?vue&type=template&id=50b44e4e&scoped=true&", function () {
      api.rerender('50b44e4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"
export default component.exports